<template>
  <div class="landingPage">
    <div v-if="user" class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- eslint-disable vue/no-v-html -->
            <div v-html="trainingHtml"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
export default {
  data() {
    return {
      trainingHtml:'',
      user: {
        agency: {
          whitelabel: {},
          landing_page: {}
        },
      }
    }
  },
  computed: {
  },
  mounted() {
    this.user = Vue.util.extend({}, this.$store.getters['auth/user'])
    this.getTrainingPage();
  },
  methods: {
      getTrainingPage(){
        this.$store
            .dispatch('agency/getTraining')
            .then((resp) => {
              this.loadingUpdate = false
              this.trainingHtml = resp.data.data;
            })
            .catch((err) => {
              if (err.response.data.errors) {
                this.$refs.trainingForm.setErrors(err.response.data.errors)
              }
              this.loadingUpdate = false
            })
      }
  },
}
</script>
<style lang="scss">

</style>
